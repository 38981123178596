import axios, { AxiosResponse } from 'axios';
import constants from '@/utils/constant';
import helper from '@/utils/helper';
import { alertModal } from '@/utils/alert';

const baseRepository = axios.create({
  timeout: constants.TIME_OUT_AXIOS
});

baseRepository.defaults.baseURL = `${process.env.VUE_APP_ENDPOINT_API}`;

baseRepository.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response.status === 204) {
      window.location.href = '/notfound';
    }

    return response.data;
  },
  (error) => {
    if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
      alertModal.fire({
        title: 'タイムアウトしました。再度やってください'
      });
      return Promise.reject({});
    }
    const status = error.response.status;
    let errors = {};

    switch (status) {
      case 400:
        errors = Object.assign(
          {},
          error.response.data.failure.map(
            (message: { error: string }) => message.error
          )
        );
        break;
      case 403:
        helper.redirectToWp(constants.RESOURCE_LOGIN_WP_ERR_403);
        break;
      case 404:
        window.location.href = '/notfound';
        break;
      case 422:
        errors = error.response.data.errors;
        break;
      case 500:
        helper.redirectToWp(constants.RESOURCE_LOGIN_WP_ERR_500);
        break;
    }

    return Promise.reject(errors);
  }
);
export default baseRepository;
