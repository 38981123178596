import moment from 'moment';
import { SearchDataInterface, SearchParamsInterface } from '@/types';

import { usePermissionsRolesStore } from '@/store/permissionsRoles';
import { rolesConfig } from '@/json/roles_cofig';
import constants from '@/utils/constant';
import { IssueInterface } from '@/types/issue';

/**
 * Print request/response data when error happen
 *
 * @param {string} type
 * @param {string} url
 * @param {string|object} data
 */
const writeLog = (type: string, url: string, data: string | object): void => {
  /* eslint-disable no-console */
  if (!process.env.VUE_APP_DEBUG || process.env.VUE_APP_DEBUG === 'true') {
    switch (type) {
      case 'Response':
        console.log('=========== Start ===========');
        console.log(type + ' for request url:' + url);
        console.log(data);
        console.log('============ End ============');
        break;
      case 'Error':
        console.error(type + ' for request url:' + url, data);
        break;
      default:
        break;
    }
  }
  /* eslint-enable no-console */
};

/**
 * Convert datetime value to local time
 *
 * @param {string} value date value
 * @param {boolean} showTime
 * @param {string} formatDate
 * @returns {string}
 */
const convertToLocalTime = (
  value: string,
  showTime: boolean = false,
  formatDate: string = 'YYYY/MM/DD'
): string => {
  if (!value) {
    return '';
  }

  const formatType: string = showTime ? formatDate + ' HH:mm' : formatDate;
  const dateFormat: Date = moment
    .utc(value, 'YYYY-MM-DD[T]HH:mm:ss[Z]')
    .local()
    .toDate();

  return moment(dateFormat, formatType).format(formatType);
};

/**
 * convert date to utc time
 *
 * @param {string} date
 * @param {boolean} hasTime
 * @param {string} format
 * @returns {string}
 */
const convertToUTCTime = (
  date?: string,
  hasTime: boolean = false,
  format: string = 'YYYY/MM/DD'
): string => {
  if (!date) {
    return '';
  }
  if (moment(date, format).format(format) === date) {
    const momentDate = moment(date, format);
    if (!hasTime) {
      momentDate.set({ hour: 23, minute: 59, second: 59 });
    }

    return momentDate.toISOString().slice(0, -5) + 'Z';
  }

  return date;
};

/**
 * convert number
 *
 * @param {number} number
 * @returns {string}
 */
const convertNumber = (number: number): string =>
  number.toLocaleString('ja-JP');
/**
 * handle show date range
 *
 * @param {Array} dateArray
 * @returns {string}
 */
const handleShowDateRange = (dateArray: Array<string>): string =>
  Object.values(dateArray).join(' ~ ');

/**
 * handle params search
 *
 * @param {object} dataSearch
 * @returns {object}
 */
const handleParamsSearch = (dataSearch: SearchDataInterface): object => {
  const params: SearchParamsInterface = {};
  if (dataSearch.page && dataSearch.page > 1) {
    params.page = dataSearch.page;
  }

  if (dataSearch.date && dataSearch.date !== '') {
    const dateArray: string[] = dataSearch.date.split(' ~ ');

    const inputFormat: string = 'YYYY-MM-DDTHH:mmZ';
    const outputFormat: string = 'YYYYMMDDHHmm';

    const startDateUTC = moment(dateArray[0] + ' 00:00', inputFormat)
      .utc()
      .format(outputFormat);

    const endDateUTC = moment(dateArray[1] + ' 23:59', inputFormat)
      .utc()
      .format(outputFormat);

    params.updated_at = startDateUTC + '-' + endDateUTC;
  }

  if (dataSearch.keyword && dataSearch.keyword !== '') {
    params.q = dataSearch.keyword;
  }

  if (dataSearch.status) {
    params.status = dataSearch.status;
  }

  return params;
};

/**
 * check access with role
 *
 * @param {string} screen
 * @returns {boolean}
 */
const checkRoles = (screen: string): boolean => {
  const storePermissionsRoles = usePermissionsRolesStore();
  if (storePermissionsRoles.role) {
    for (const role of rolesConfig) {
      if (role.name === screen.replace(/\./g, '_')) {
        if (Object.keys(role.roles).includes(storePermissionsRoles.role)) {
          return true;
        }
      }
    }
    return false;
  }
  return false;
};

const checkDisabled = (name: string, hash: string): boolean => {
  const screen = hash ? name + '_' + hash.replace(/#/g, '') : name;
  const storePermissionsRoles = usePermissionsRolesStore();

  for (const role of rolesConfig) {
    if (role.name === screen.replace(/\./g, '_')) {
      for (const [key, value] of Object.entries(role.roles)) {
        if (key === storePermissionsRoles.role) {
          return value === 'full';
        }
      }
    }
  }

  return false;
};

/**
 * redirect to word press
 *
 * @param {string} code
 */
const redirectToWp = (code: string): void => {
  window.location.href = process.env.VUE_APP_ENDPOINT_WP + code;
};

const loginWp = (): void => {
  localStorage.removeItem('userData');
  window.location.href = process.env.VUE_APP_ENDPOINT_WP_LOGIN;
};

/**
 * get full name in pinia
 *
 * @returns {string}
 */
const fullName = (): string => {
  const storePermissionsRoles = usePermissionsRolesStore();
  return storePermissionsRoles.fullname;
};

const sortOrderRoles = (roles: string[]): string => {
  let roleMax: string = constants.ROLE_NONE;
  for (const roleOrder of constants.ROLES_ORDER) {
    for (const role of roles) {
      if (role === roleOrder) {
        roleMax = role;

        return roleMax;
      }
    }
  }

  return roleMax;
};

/**
 * check issue published
 *
 * @param {IssueInterface} issue
 * @returns {boolean}
 */
const isIssuePublished = (issue: IssueInterface): boolean =>
  new Date() > new Date(issue.publish_start_at) && issue.status === 'published';

/**
 * check issue in queue
 *
 * @param {IssueInterface} issue
 * @returns {boolean}
 */
const isIssueInQueue = (issue: IssueInterface): boolean =>
  issue.queued_at && issue.publish_start_at && issue.status !== 'published';

const disabledFormByRole = (
  container: any,
  name: string,
  hash: string,
  selector: string
): void => {
  const elements = container.value?.querySelectorAll(selector);
  if (elements) {
    elements.forEach(
      (element: HTMLInputElement | HTMLButtonElement | HTMLTextAreaElement) => {
        if (!element.disabled) {
          element.disabled = !checkDisabled(name, hash);
        }
      }
    );
  }
};
export default {
  writeLog,
  convertToLocalTime,
  convertToUTCTime,
  convertNumber,
  handleShowDateRange,
  handleParamsSearch,
  checkRoles,
  redirectToWp,
  fullName,
  sortOrderRoles,
  checkDisabled,
  disabledFormByRole,
  isIssuePublished,
  isIssueInQueue,
  loginWp
};
