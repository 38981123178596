<template>
  <div id="app-layout">
    <component :is="route.meta.layout">
      <router-view v-slot="{ Component }">
        <suspense>
          <template #default>
            <component
              :is="Component"
              :key="$route.path"
            />
          </template>
          <template #fallback>
            <div>ローディング中...</div>
          </template>
        </suspense>
      </router-view>
    </component>
  </div>
  <Loading
    v-if="isLoading"
    :active="true"
    :is-full-page="true"
    loader="bars"
  />
</template>

<script setup>
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';

import Loading from 'vue-loading-overlay';
import { useLoadingStore } from '@/store/loading';

const { isLoading } = storeToRefs(useLoadingStore());

const route = useRoute();
</script>

<style lang="scss">
@import '@/assets/css/app.scss';
</style>
