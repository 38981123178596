<template>
  <div id="layout-header">
    <div class="header">
      <div class="d-flex align-items-center gap-5">
        <div>
          <router-link :to="{ name: 'home' }">
            <img
              :src="require('@/assets/images/logo.svg')"
              alt="ロゴ"
              height="50"
            />
          </router-link>
        </div>
        <div>
          <p class="title-logo">制作者メニュー</p>
        </div>
      </div>
      <div class="list-menu">
        <ul class="d-flex mb-0 list-unstyled">
          <li v-if="helper.checkRoles('home') && showButton">
            <router-link
              class="d-flex align-items-center gap-1"
              :to="{ name: 'home' }"
            >
              <HomeIcon />
              <span>HOME</span>
            </router-link>
          </li>
          <li v-if="helper.checkRoles('article.list') && showButton">
            <router-link
              class="d-flex align-items-center gap-1"
              :to="{ name: 'article.create' }"
            >
              <ArticleIcon />
              <span>原稿の新規作成</span>
            </router-link>
          </li>
          <li v-if="helper.checkRoles('newsletter.list') && showButton">
            <router-link
              class="d-flex align-items-center gap-1"
              :to="{ name: 'newsletter.list' }"
            >
              <NewsLetterIcon />
              <span>ニューズレター</span>
            </router-link>
          </li>
          <li v-if="helper.checkRoles('user.detail') && showButton">
            <router-link
              class="d-flex align-items-center gap-1"
              :to="{ name: 'user.detail' }"
            >
              <UserIcon />
              <span>ユーザー</span>
            </router-link>
          </li>
          <BaseButton
            v-if="helper.checkRoles('home') && showButton"
            title="ログアウト"
            class="logout-btn"
            @click="helper.loginWp()"
          />
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import HomeIcon from '@/components/shared/icons/header/HomeIcon.vue';
import ArticleIcon from '@/components/shared/icons/header/ArticleIcon.vue';
import NewsLetterIcon from '@/components/shared/icons/header/NewsLetterIcon.vue';
import UserIcon from '@/components/shared/icons/header/UserIcon.vue';
import BaseButton from '@/components/shared/BaseButton.vue';
import helper from '@/utils/helper';
import { useRoute } from 'vue-router';
import { computed } from 'vue';
const route = useRoute();
const showButton = computed(
  () => !['invite.confirm'].includes(String(route.name))
);
</script>

<style scoped lang="scss">
@import '@/assets/css/header.scss';
</style>
