const rolesConfig = [
  {
    name: 'home',
    roles: {
      publisher: 'full',
      editor: 'full',
      writer: 'full',
      role_none: 'full'
    }
  },
  {
    name: 'user_detail',
    roles: {
      publisher: 'full',
      editor: 'full',
      writer: 'full',
      role_none: 'full'
    }
  },
  {
    name: 'user_detail_basic',
    roles: {
      publisher: 'full',
      editor: 'full',
      writer: 'full',
      role_none: 'full'
    }
  },
  {
    name: 'user_detail_billing',
    roles: {
      publisher: 'full',
      editor: 'full',
      writer: 'full',
      role_none: 'full'
    }
  },
  {
    name: 'user_detail_wallet',
    roles: {
      publisher: 'full',
      editor: 'full'
    }
  },
  {
    name: 'user_detail_unsubscribe',
    roles: {
      publisher: 'full',
      editor: 'full',
      writer: 'full',
      role_none: 'full'
    }
  },
  {
    name: 'user_detail_password',
    roles: {
      publisher: 'full',
      editor: 'full',
      writer: 'full',
      role_none: 'full'
    }
  },
  {
    name: 'user_detail_option',
    roles: {
      publisher: 'full',
      editor: 'full',
      writer: 'full',
      role_none: 'full'
    }
  },
  {
    name: 'article_list',
    roles: {
      publisher: 'full',
      editor: 'full',
      writer: 'full',
      role_none: 'full'
    }
  },
  {
    name: 'article_detail_body',
    roles: {
      publisher: 'full',
      editor: 'full',
      writer: 'full',
      role_none: 'full'
    }
  },
  {
    name: 'article_detail_attr',
    roles: {
      publisher: 'full',
      editor: 'full',
      writer: 'full',
      role_none: 'full'
    }
  },
  {
    name: 'article_detail_issue',
    roles: { publisher: 'full', editor: 'full', writer: 'view' }
  },
  {
    name: 'article_create',
    roles: {
      publisher: 'full',
      editor: 'full',
      writer: 'full',
      role_none: 'full'
    }
  },
  {
    name: 'article_create_attr',
    roles: {
      publisher: 'full',
      editor: 'full',
      writer: 'full',
      role_none: 'full'
    }
  },
  {
    name: 'article_detail',
    roles: {
      publisher: 'full',
      editor: 'full',
      writer: 'full',
      role_none: 'full'
    }
  },
  {
    name: 'article_focus',
    roles: {
      publisher: 'full',
      editor: 'full',
      writer: 'full',
      role_none: 'full'
    }
  },
  {
    name: 'article_detail_sns',
    roles: { publisher: 'full', editor: 'full' }
  },
  {
    name: 'folder_detail',
    roles: {
      publisher: 'full',
      editor: 'full',
      writer: 'full',
      role_none: 'full'
    }
  },
  {
    name: 'folder_detail_attr',
    roles: {
      publisher: 'full',
      editor: 'full',
      writer: 'full',
      role_none: 'full'
    }
  },
  {
    name: 'folder_create',
    roles: {
      publisher: 'full',
      editor: 'full',
      writer: 'full',
      role_none: 'full'
    }
  },
  {
    name: 'folder_create_attr',
    roles: {
      publisher: 'full',
      editor: 'full',
      writer: 'full',
      role_none: 'full'
    }
  },
  {
    name: 'newsletter_list',
    roles: { publisher: 'full', editor: 'full', writer: 'full' }
  },
  {
    name: 'newsletter_detail',
    roles: { publisher: 'full', editor: 'view', writer: 'view' }
  },
  {
    name: 'newsletter_detail_attr',
    roles: { publisher: 'full', editor: 'view', writer: 'view' }
  },
  {
    name: 'newsletter_detail_issue',
    roles: { publisher: 'full', editor: 'full' }
  },
  {
    name: 'newsletter_detail_member',
    roles: { publisher: 'full', editor: 'view', writer: 'view' }
  },
  {
    name: 'newsletter_detail_task',
    roles: { publisher: 'full', editor: 'full', writer: 'full' }
  },
  {
    name: 'newsletter_detail_gantt',
    roles: { publisher: 'full', editor: 'full', writer: 'full' }
  },
  {
    name: 'newsletter_create',
    roles: {
      publisher: 'full',
      editor: 'full',
      writer: 'full',
      role_none: 'full'
    }
  },
  { name: 'issue_create', roles: { publisher: 'full', editor: 'full' } },
  { name: 'issue_create_attr', roles: { publisher: 'full', editor: 'full' } },
  { name: 'issue_detail', roles: { publisher: 'full', editor: 'full' } },
  { name: 'issue_detail_attr', roles: { publisher: 'full', editor: 'full' } },
  {
    name: 'issue_detail_article',
    roles: { publisher: 'full', editor: 'full' }
  },
  {
    name: 'issue_detail_publish',
    roles: { publisher: 'full', editor: 'view' }
  },
  { name: 'queue_list', roles: { publisher: 'full', editor: 'full' } },
  { name: 'task_detail', roles: { publisher: 'full', editor: 'full' } },
  { name: 'task_detail_attr', roles: { publisher: 'full', editor: 'full' } },
  { name: 'task_create', roles: { publisher: 'full', editor: 'full' } },
  { name: 'task_create_attr', roles: { publisher: 'full', editor: 'full' } },
  {
    name: 'newsletter_user_detail',
    roles: { publisher: 'full', editor: 'view' }
  },
  {
    name: 'newsletter_user_detail_basic',
    roles: { publisher: 'full', editor: 'view' }
  }
];

export { rolesConfig };
