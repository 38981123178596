<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.299"
    height="16"
    viewBox="0 0 16.299 16"
  >
    <g
      id="枠つきの羽根ペンのアイコン素材_1_"
      data-name="枠つきの羽根ペンのアイコン素材 (1)"
      transform="translate(-0.001 -4.702)"
    >
      <path
        id="Path_1064"
        data-name="Path 1064"
        d="M167.529,4.744c-3.4.469-6.647,6.839-8.247,9.788-.4.742.3,1.127.714.418.3-.51,1.022-1.99,1.414-1.913,1.872.312,2.87-.909,2.161-1.677,2.381.049,3.562-1.261,2.886-2.057A3.566,3.566,0,0,0,169.123,9C171.073,7.934,170.955,4.272,167.529,4.744Z"
        transform="translate(-154.093 0)"
      />
      <path
        id="Path_1065"
        data-name="Path 1065"
        d="M13.027,94.332a4.807,4.807,0,0,1-1.323.885v6.592a.52.52,0,0,1-.52.52h-9.1a.52.52,0,0,1-.52-.52v-9.1a.52.52,0,0,1,.52-.52H7.188c.358-.545.724-1.069,1.1-1.56h-6.2A2.08,2.08,0,0,0,0,92.707v9.1a2.08,2.08,0,0,0,2.081,2.081h9.1a2.08,2.08,0,0,0,2.081-2.081V94.3S13.181,94.188,13.027,94.332Z"
        transform="translate(0 -83.188)"
      />
    </g>
  </svg>
</template>
