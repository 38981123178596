<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <g
      id="Group_1657"
      data-name="Group 1657"
      transform="translate(-1488 72)"
    >
      <rect
        id="Rectangle_1087"
        data-name="Rectangle 1087"
        width="16"
        height="16"
        transform="translate(1488 -72)"
        fill="none"
      />
      <g
        id="ic-_human"
        transform="translate(1488 -72.111)"
      >
        <path
          id="Path_1066"
          data-name="Path 1066"
          d="M119.2,8.937a4.413,4.413,0,1,0-4.413-4.413A4.413,4.413,0,0,0,119.2,8.937Z"
          transform="translate(-111.198)"
        />
        <path
          id="Path_1067"
          data-name="Path 1067"
          d="M15.742,330.579c-.935-2.875-4.367-4.44-7.742-4.44S1.193,327.7.258,330.579A5.394,5.394,0,0,0,0,331.944H16A5.411,5.411,0,0,0,15.742,330.579Z"
          transform="translate(0 -315.84)"
        />
      </g>
    </g>
  </svg>
</template>
