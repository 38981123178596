/**
 * handle middleware pipeline
 *
 * @param {object} context
 * @param {object} context.next
 * @param {Array} middleware
 * @param {number} index
 * @returns {object}
 */
export default function middlewarePipeline(
  context: { next: object },
  middleware: Array<Function>,
  index: number
): object {
  const nextMiddleware = middleware[index];

  if (!nextMiddleware) {
    return context.next;
  }

  return () => {
    const nextPipeline = middlewarePipeline(context, middleware, index + 1);

    nextMiddleware({ ...context, next: nextPipeline });
  };
}
